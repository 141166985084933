<template>

  <section id="pantallas">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-tv color-secondary"></i>
          Aquí se muestran todas las pantallas que has creado
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button 
          @click="addPantalla"
          v-show="GetPantallaList.length > 0" 
          :disabled="!areThereScreensAvailable"  
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
          :class="{ 'disable': !areThereScreensAvailable }"
        >
          <font-awesome-icon icon="plus" class="pe-2"/>
          Añadir pantalla
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          Plan contratado {{ CantidadPantallasAdmin.pantallas_contrato }}
          pantallas / {{ CantidadPantallasAdmin.registradas }} Pantallas registradas
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Spinner v-if="show_spinner"/>
        <PantallasTable 
          :pantallas="GetPantallaList" 
          @deleteScreen="deleteScreen" 
          @playRequest="playRequest"
        />
      </div>
    </div>

    <Question 
      v-if="question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelDelete"
      @accept="acceptDelete" 
    />

<!--     <section class="home-pantallas">
      <h2 class="organigrama-section__title">
        Aquí se muestran todas las pantallas que has creado
      </h2>
      <div class="home-pantallas__title-container">
        <h2 class="home-pantallas__title">
          Listado de pantallas
          <span class="home-pantallas__title-purple">Plan contratado {{ CantidadPantallasAdmin.pantallas_contrato }}
            pantallas / {{ CantidadPantallasAdmin.registradas }} Pantallas registradas</span>
        </h2>
        <button
          @click="addPantalla"
          v-show="GetPantallaList.length > 0" 
          :class="{
            'home-pantallas__add-button': areThereScreensAvailable,
            'home-pantallas__add-disable': !areThereScreensAvailable,
          }"
          :disabled="!areThereScreensAvailable"  
        >
            + Añadir Pantalla
        </button>
      </div>
      <div class="home-pantallas__table-container">
        <Spinner v-if="show_spinner" />
        <PantallasTable :pantallas="GetPantallaList" @deleteScreen="deleteScreen" @playRequest="playRequest" />
      </div>
      <div class="home-pantallas__modal">
        <AgregarPantallas/>
      </div>
      <Question v-if="question_modal" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelDelete"
        @accept="acceptDelete" />
    </section> -->

  </section>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PantallasTable from "./PantallasTable";
//import AgregarPantallas from "./AgregarPantallas.vue";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
export default {
  components: {
    PantallasTable,
    //AgregarPantallas,
    Spinner,
    Question,
  },
  data() {
    return {
      show_spinner: false,
      question_modal: false,
      question_modal_msg: "",
      dataDelete: {},
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
    };
  },

  computed: {
    ...mapState("canalCorporativoModule", ["GetPantallaList", "CantidadPantallasAdmin"]),
    ...mapGetters("canalCorporativoModule", ["areThereScreensAvailable"]),
  },

  created() {
    this.getPantallaListFunction();
    this.getCantidadPantallasFunction();
  },

  methods: {
    ...mapActions("canalCorporativoModule", [
      "GetPantallaListAction",
      "GetCantidadPantallasAction",
      "DeleteScreenAction"
    ]),

    addPantalla() {
      this.$router.push({ name: 'CanalCorporativo-add-pantallas' });
    },


    async getPantallaListFunction() {
      this.show_spinner = true;
      let data_ = {
        id_empresa: this.id_empresa,
      }
      let result = await this.GetPantallaListAction(data_);
      if (result == null) {
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
        this.show_spinner = false;
      } else {
        this.show_spinner = false;
      }
    },
    async getCantidadPantallasFunction() {
      let data_ = {
        id_empresa: this.id_empresa,
      }
      let result = await this.GetCantidadPantallasAction(data_);
      if (result == null) {
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
    },
    async acceptDelete() {
      let data_ = this.dataDelete
      let result = await this.DeleteScreenAction(data_).then(result => { return result });
      if (result != null) {
        if (result.id_pantalla != null) {
          this.question_modal = false;
          this.show_spinner = true;
          this.$toast.open({
            message: "Tu pantalla ha sido eliminada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          setTimeout(() => {
            this.show_spinner = false;
          }, 1000);
        } else {
          this.$toast.open({
            message: "No se pudo eliminar la pantalla, por favor intentar más tarde",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
          setTimeout(() => {
            this.show_spinner = false;
          }, 1000);
        }
      } else {
        this.$toast.open({
          message: "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
        setTimeout(() => {
          this.show_spinner = false;
        }, 1000);
      }

    },
    cancelDelete() {
      this.question_modal = false;
    },
    deleteScreen(data) {
      this.dataDelete = data;
      this.question_modal = true;
      this.question_modal_msg =
        "Al confirmar esta acción, se desvinculará el dispositivo de la pantalla, ¿Deseas continuar?";
    },
    playRequest() {
      this.show_spinner = true;
      setTimeout(() => {
        this.show_spinner = false;
        this.$toast.open({
          message: "Tu pantalla se esta reproduciendo correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      }, 2000);
    },
  },
};
</script>
