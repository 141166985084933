<template>
  <div class="table-container">
    <table class="table table-custom table-borderless table-custom-responsive mb-0">
      <thead>
        <tr>
          <th scope="col">N°</th>
          <th scope="col">Nombre</th>
          <th scope="col">Código</th>
          <th scope="col">Canal</th>
          <th scope="col">Estado</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody v-show="pantallas.length > 0">
        <tr
          v-for="(pantalla, i) in pantallas"
          :key="i"
        >
          <td class="th th-numero">{{ pantalla.id_pantalla }}</td>
          <td class="th th-nombre">{{ pantalla.nombre_pantalla }}</td>
          <td class="th th-codigo">{{ pantalla.codigo }}</td>
          <td class="th th-canal">
            <span
              v-show="pantalla.canal.length >= 1"
              v-for="(c, i) in pantalla.canal"
              :key="i"
            >
              {{ c.nombre_canal }}
          </span>
            <span v-show="pantalla.canal.length === 0">-</span>
          </td>
          <td class="th th-estado">
            <span 
              v-if="pantalla.estado.nombre_estado === 'Online'"
              class="badge badge-custom badge-custom-green-light d-block">
              {{ pantalla.estado.nombre_estado }}
            </span>
            <span 
              v-if="pantalla.estado.nombre_estado === 'Offline'"
              class="badge badge-custom badge-custom-red-light d-block">
              {{ pantalla.estado.nombre_estado }}
            </span>

<!--             <div class="pantallas-table__estado-container">
              <div
                class="pantallas-table__circle"
                :style="{ 'background-color': `${pantalla.estado != null ? pantalla.estado.color : '0'}` }"
              ></div>
              <div class="ps-2">{{ pantalla.estado.nombre_estado }}</div>
            </div> -->
          </td>
          <td class="th th-accion text-center">

            <div class="dropdown dropdown-custom d-flex justify-content-center">
                <button 
                  @click="openOption(pantalla.id_pantalla)"
                  class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                  type="button"
                  data-bs-toggle="dropdown">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul 
                  class="dropdown-menu"
                  v-click-outside="outOption">
                  <li>
                    <a 
                      @click="editScreen(pantalla.id_pantalla)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                      Editar
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="playRequest"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-main" icon="play"/>
                      Reproducir
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="deleteScreen(pantalla.id_pantalla)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                      Eliminar
                    </a>
                  </li>
                </ul>
            </div>

<!--             <div class="pantallas-table__actions">
              <img
                @click="openOption(pantalla.id_pantalla)"
                class="pantallas-table__dots"
                src="@/assets/img/qiyab.png"
                height="15"
                alt=""
              />
              <div
                class="options-encuesta"
                v-click-outside="outOption"
                v-show="option == pantalla.id_pantalla"
              >
                <div
                  @click="editScreen(pantalla.id_pantalla)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/Editar.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Editar</div>
                </div>
                <div @click="playRequest" class="options-encuesta__option ps-2">
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/reproducir.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Reproducir</div>
                </div>
                <div
                  @click="deleteScreen(pantalla.id_pantalla)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/Eliminar.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Eliminar</div>
                </div>
              </div>
            </div> -->
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-show="pantallas.length == 0"
      class="pantallas-table__nodata-container"
    >
      <img
        src="@/assets/img/question_face.svg"
        alt=""
        class="pantallas-table__nodata-img"
      />
      <p class="pantallas-table__nodata-text">
        Aún no has creado pantallas. Has clic en el siguiente botón para
        vincular una pantalla.
      </p>
      <router-link
        :to="{ name: 'CanalCorporativo-add-pantallas' }"
        class="pantallas-table__nodata-button"
        >+ Añadir Pantalla</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pantallas: {
      type: Array,
    },
  },
  
  data() {
    return {
      option: "-1",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
    };
  },

  methods: {
    openOption(i) {
      if (this.option == i) {
        this.option = -1;
      } else {
        setTimeout(() => {
          this.option = i;
        }, 10);
      }
    },
    outOption() {
      if (this.option != -1) {
        this.option = -1;
      }
    },
    deleteScreen(id_pantalla) {
      let data_ = { 
        id_pantalla: id_pantalla,
        id_empresa: this.id_empresa,
        id_usuario: this.id_usuario
      }
      this.$emit("deleteScreen", data_);
    },
    playRequest() {
      this.$emit("playRequest");
    },
    editScreen(id_) {
      this.$router.push({
        name: "CanalCorporativo-edit-pantallas",
        params: {
          id: id_,
        },
      });
    },
  },
};
</script>
